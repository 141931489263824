.reusable-dialog-form-control-label {
  display: inline-flex;
}
.reusable-dialog-form-control-label {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 8px;
}


.confirmation-dialog {
  &__actions {
    padding: 0 24px 16px 24px !important;
  }

  &__button {
    &-icon {
      position: relative;
      bottom: 2px;
    }
  }
}







.update-release {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  padding-bottom: 0;
 
  gap: 50px;
  
  @media screen and (min-width: 900px) {
    flex-direction: row;
    align-items: center;
   
  }
}

.update-release__image-container {
  flex: 1;
  aspect-ratio: 1;
  background-image: url("../../../public/assets/images/undraw_happy_news_re_tsbd\ \(1\).svg");
  background-repeat: no-repeat;
  background-size: contain;
 max-height: 353px;
 background-position: center;

  @media screen and (min-width: 900px) {
  }
  &--faq {
    background-image: url("../../../public/assets/images/question-mark-green.svg");

  }
  &--error {
    background-image: url("../../../public/assets/images/undraw_qa_engineers_dg-5-p.svg");
  }
}

.update-release__image {
  width: 100%; // Ensure the image takes up 100% of its container
  height: auto; // Maintain aspect ratio and prevent distortion
}

.update-release__content {
  flex: 2;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%; // Take up full width initially
  box-sizing: border-box;

  @media screen and (min-width: 900px) {
  }
}





.update-release__section {}

.update-release__title {}

.update-release__subheading {
  // margin-bottom: 10px !important;
  font-weight: 500 !important;
}

.update-release__body {
  margin-top: 10px !important;
}

.visually-hidden {
  display: none;
}

.clubready-scrape__account-selector {
  height: 100%;
  flex: 1;
}

.clubready-account-selector {
  height: 100%;
}

.clubready-account-selector__button {
  width: 100%;
  height: 100%;
  /* max-height: 56px; */
}

.clubready-account-selector__button-badge {
  width: 100%;
  height: 100%;
}

.account-manager-dialog__list-item {
  &--dropdown {
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

&--error {
// border: 4px solid red;
opacity: .75;
}
&--pending {
  opacity: .75;
// border: 4px dotted blue;
}
&--validated {
// border: 8px solid green;
}
 
}

.account-manager-dialog__list-item-tooltip {

}
.MuiTooltip-tooltip {
  margin: 0;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  padding: 16px !important;
  letter-spacing: 0.00938em;
  max-width: 600px !important;


}
.account-manager-dialog__list-item-tooltip .MuiTooltip-tooltip {



}


.account-manager-dialog__list-item-text-container {
  display: flex;
  align-items: flex-start;
}
.account-manager-dialog__list-item-chevron {
  margin-top: 6px;
}

.account-manager-dialog .MuiDialog-paper {
  width: 80%;
  height: fit-content;
}
.account-manager-dialog__refresh-button {
  &--rotate-infinite {
    animation: rotate 0.75s infinite linear;
  }
}
.account-manager-dialog__list-item-avatar-container {
  position: relative;
}
.account-manager-dialog__list-item-avatar {
  position: relative;
}
.account-manager-dialog__list-item-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;

  .MuiCircularProgress-circle {
    stroke-width: 1.6;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 900px) {
  .account-manager-dialog .MuiDialog-paper {
    width: 50%;
  }
}

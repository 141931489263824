@use "../../Styles/variables.scss" as *;
@use "../../Styles/mixins" as mixins;


$drawerWidth: 270px;

.drawer-container {
  position: relative;
  // top: 80px;
  margin-top: 80px;

  @include mixins.for-tablet-landscape-up {
    // top: 64px;
    margin-top: 64px;
  }
}

.drawer__appbar {
  display: flex !important;
  background-color: transparent !important;
  color: #000000de !important;
  box-shadow: none !important;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;

  @include mixins.for-tablet-landscape-up {
    display: none !important;
    width: calc(100% - $drawerWidth) !important;
    margin-left: $drawerWidth;
  }
}

.drawer__toolbar {
  padding-left: $gutters;
  padding-right: $gutters;
}

.drawer__nav-drawer {
  display: block;
  

  .MuiPaper-root {
    padding-top: 80px !important;
    width: $drawerWidth;
    box-sizing: border-box;

    @include mixins.for-tablet-landscape-up {
      padding-top: 64px !important;
    }
  }

  @include mixins.for-tablet-landscape-up {
    display: none;
  }
}

.drawer__nav-drawer--desktop {
  display: none;

  // Desktop
  @include mixins.for-tablet-landscape-up {
    display: block;

    .MuiPaper-root {
      padding-top: 80px !important;
      width: $drawerWidth;
      box-sizing: border-box;
      

      @include mixins.for-tablet-landscape-up {
        padding-top: 64px !important;
      }
    }
  }
}

.drawer__page-content {
  // border: 5px solid blue;
  width: 100%;
  // padding: 0px 12px 0 12px;
  // height: 100vh;

  @include mixins.for-tablet-landscape-up {
    padding-left: calc($drawerWidth);
  }
}

.drawer__icon-button {
  margin-right: 2px !important;
  display: block !important;

  @include mixins.for-tablet-landscape-up {
    display: none !important;
  }
}

.drawer__icon-button svg {
  vertical-align: unset;
}

.drawer__back-button {
  margin: 10px $gutters !important;
  padding-left: $gutters !important;

  @include mixins.for-tablet-landscape-up {
    margin: 10px calc($gutters-desktop / 2) !important;
    padding-left: calc($gutters-desktop / 2) !important;
  }
}

.drawer__h6 {
  font-weight: 400 !important;
  font-size: 1rem !important;
}
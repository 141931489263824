.download-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  &__success {
   display: flex;
  flex-direction: column;
  align-items: center;

  }
  &__success h4 {
      margin: 0;
  }

  &__success svg {
    margin-right: 8px;
}
  &__success-text {
    display: flex;
     align-items: center;
     justify-content: center;
  }

  &__success-button {
   margin-top: 8px;
  }


  
  &__h4 {
    text-align: center;
  }


 
  &__chip {
    margin-left: 7px;
    margin-right: 7px;
  }
}




.category-page {
    font-family: "Open Sans", sans-serif;
    background-color: #f7f8fa;
    padding: 20px;
}
.category-page .header {
    border-bottom: 1px solid #e0e4e8;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  .drawer__back-button {
    
    /* font-weight: 600;
    color: #13C296;
    font-size: 14px;
    padding: 4px 8px;
    align-self: flex-start;
    margin-bottom: 10px;
    text-transform: none; */
  }
  
  .logo img {
    height: 40px;
    cursor: pointer;
  }
  
  .paywell-logo {
    height: 40px;
    width: auto;
  }
  

  .main-content {
    padding: 40px 0;
  }
  
  .resource-content {
    margin-top: 60px;
  }
  .resource-content h1 {
    font-weight: 300 !important;
    font-size: 32px !important;
    line-height: 42px !important;
    margin-bottom: 24px !important;
    color: #333333;
  }
  
  
  .general-info {
    /* display: flex;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;
    color: #9b9b9b;
    margin-bottom: 30px; */
line-height: 28px;
    padding-bottom: 5px;
    border-bottom: 1px solid #C5C7D0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .general-info p{
    font-size: 16px;
    font-weight: 400;
    vertical-align: text-top;
    line-height: 23px;
    color: #676879;
    margin: 0 !important;
  }
  
  .general-info .icon {
    vertical-align: middle;
    margin-right: 5px;
    color: #13C296;
  }
  
  .video-container iframe {
    width: 100%;
    height: 500px;
    border: none;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .chapters-section {
    margin-bottom: 40px;
  }
  
  .chapters-section h2 {
    font-size: 24px;
    font-weight: 700;
    color: #292e35;
    margin-bottom: 20px;
  }
  
  .chapters-list {
    list-style: none;
    padding: 0;
  }
  
  .chapters-list li {
    margin-bottom: 10px;
  }
  
  .chapter-link {
    font-size: 16px;
    text-decoration: none;
  }
  
  .chapter-link:hover {
    text-decoration: underline;
  }
  
  .text-content p {
    font-size: 16px;
    color: #4a4f55;
    margin-bottom: 20px;
  }
  
  .attachments-section,
  .related-section,
  .support-section {
    margin-top: 40px;
  }
  
  .attachments-section h2,
  .related-section h2,
  .support-section h2 {
    font-size: 24px;
    font-weight: 700;
    color: #292e35;
    margin-bottom: 20px;

  }
  
  .attachments-section ul,
  .related-section ul {

    list-style: none;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    padding: 0;
  }
  
  .attachments-section li,
  .related-section li {
    margin-left: 0;
    margin-right: 8px;
    display: inline-block;
    margin-bottom: 12px;
  }
  
  .attachments-section a,
  .related-section a {
    color: #1F76C2;
    font-size: 16px;
    padding: 8px 16px;
    display: block;
    border: 1px solid #1F76C2;
    border-radius: 5px;
    text-decoration: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  
  .attachments-section a:hover,
  .related-section a:hover {
    text-decoration: underline;
  }
  
  .support-section p {
    font-size: 16px;
    color: #4a4f55;
  }
  
  .support-section a {
  }
  
  .support-section a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .video-container iframe {
      height: 300px;
    }
  
    .general-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
@use "../../Styles/variables.scss" as *;
@use "../../Styles/mixins" as mixins;

.homepage-page-container {
  background-color: white;
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 0px $gutters;
  overflow: hidden;
}

.homepage-page {
  padding-bottom: 80px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 0px;

  &--no-bottom-padding {
    padding-bottom: 0;
  }
}

.featured-row__image,
.featured-row__image-gif {
  max-width: 100%;
  height: auto;
}
.featured-row__image {
  max-width: 45%;
  left: 0;
  right: 0;
  margin: auto;
}

@include mixins.for-tablet-landscape-up {
  .homepage-page-container {
    padding: 0px $gutters-desktop;
  }

  .homepage-page {
    padding-top: 45px;
  }
}

// ADD CLASS COMPONENT
.add-class-component {
}
.add-class-component__content {
  margin: 0em 0 2em 0;
}
.add-class-component__text {
  text-wrap: pretty;
  span {
    text-decoration: underline;
    font-weight: 500;
  }
}
.add-class-component__caption {
  text-wrap: pretty;
  span {
    text-wrap: pretty;
    text-decoration: underline;
    font-weight: 500;
  }
}

// ADD STAFF COMPONENT
.add-staff-component {
}
.add-staff-component__content {
  margin: 2em 0 2em 0;
}

.add-staff-component__text {
  text-wrap: pretty;
  span {
    text-wrap: pretty;
    text-decoration: underline;
    font-weight: 500;
  }
}
.add-staff-component__caption {
  text-wrap: pretty;
  span {
    text-wrap: pretty;
    text-decoration: underline;
    font-weight: 500;
  }
}

//  MERGE STAFF COMPONENT
.merge-staff-component {
}
.merge-staff-component__content {
  margin: 0em 0 2em 0;
}
.merge-staff-component__text {
  text-wrap: pretty;
  margin-bottom: 1rem !important;
  span {
    text-wrap: pretty;
    text-decoration: underline;
    font-weight: 500;
  }
}
.merge-staff-component__caption {
  text-wrap: pretty;
  span {
    word-break: keep-all;
    text-wrap: pretty;
    text-decoration: underline;
    font-weight: 500;
  }
}

.clubready-reports {
  &__tabs {
  }
  &__tab-panel {
  }
}
.scrape-status {
  // border: 1px solid green;
  // display: flex;
  // flex-wrap: wrap;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 10px;
  margin-top: 16px;
}
.scrape-status > div {
  margin: 0 !important;
}

.app-bar {
}
.app-bar__buttons {
  &--homepage {
    display: none;
  }
}
.app-bar__button {
  &--sign-in,
  &--get-started {
  }
}

.nav-bar {
  &__links {
    display: none !important;
  }
}

.iframe-skeleton-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  // background-color: blue;
}
.iframe-skeleton-loader {
  width: 70%;
  background: #f0f0f0;
  border-radius: 2px;
}

.iframe-container {
  width: 100%;
  height: 100%; /* Adjust based on your requirements */
  width: 100vw;
}

@media (min-width: 900px) {
  .nav-bar {
    &__links {
      display: flex !important;
    }
  }

  .app-bar__buttons {
    &--homepage {
      display: flex;
    }
  }
}

.output-container {
  padding: 40px;
}

.output-comparison {

}


.output-comparison {
  padding: 20px;

  &__header {
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #333;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;

      &:not(:last-child) {
        border-right: none;
      }
    }

    th {
      background-color: #f4f4f4;
      color: #333;
    }

    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
  }
}



/* Add styles for DataGrid rows based on status */
// .added-row {
// background-color: rgba(76, 175, 80, 0.1) !important; /* Light green */
// }

// .removed-row {
// background-color: rgba(244, 67, 54, 0.1) !important; /* Light red */
// }

/* Additional styles can be added as needed */


/* For example, in your global CSS or a styled component: */
.added-row {
  background-color: rgba(76, 175, 80, 0.1) !important;
}
.removed-row {
  background-color: rgba(244, 67, 54, 0.1) !important;
}
.reorder-row {
  background-color: rgba(33, 150, 243, 0.1) !important;
}
.modified-row {
  /* Optionally no background or a subtle highlight */
  background-color: rgba(255, 235, 59, 0.1) !important;
}

.list {
  margin-top: 12px !important;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 300px;
  box-shadow: inset 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 13px 25px !important;
  border: 1px solid #0000001a;
  &__item {
    border-bottom: 1px solid rgb(0 0 0 / 5%);
    padding: 13px 0 !important;
    column-gap: 10px;
    display: flex;
    grid-template-columns: auto 2fr 2fr;
    .MuiButtonBase-root:hover {
      background-color: none !important;
    }
  }
  &__item-text {
    &--wrong {
      border-bottom: 1px solid #f34748;
      background-color: #fedae3;
    }
    &--correct {
      border-bottom: 1px solid #1ebd77;
      background-color: #1ebd7726;
    }
  }
  &__name {
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.79);
    &--correct {
      color: #1ebd77;
    }
  }
  &__arrow {
    color: #b1b1b1;
  }
  &__strike-through {
    color: #f34748;
    text-decoration: line-through;
  }
}
.checkbox-checked {
  display: flex;
  align-items: center;
  column-gap: 10px;
  &__icon {
    color: #02cea2;
  }
  &__text {
    color: #00000099;
  }
  &__button {
    font-size: 12px !important;
    text-transform: capitalize !important;
    background-color: #0000000d !important;
    color: #00000099 !important;
    border: none !important;

    &:hover {
      border: none !important;
    }
  }
}

@use "../../Styles/variables.scss" as *;

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

.settings {
  $b: ".settings";
  
  position: relative;
  z-index: 0;
  top: 80px;
  @include for-tablet-landscape-up {
    top: 64px;
  }
  &__divider {
    margin: 8px !important;
  }
  &__paywell-setup {
    // padding: 16px 16px;
    padding: 78px 0px 16px 20px;
  }

  &__list {
    &-item {
      &-button {
        margin: 0 16px !important;
        border-radius: 4px !important;
        &--active {
          margin: 0 16px !important;
          position: relative !important;
          .icon,
          .list-item-text {
            color: #006bff;
          }
          &::after {
            content: "";
            position: absolute;
            // left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: #006bff;
            opacity: 0.1;
            height: 100%;
            width: 100%;
            left: 0%;
            border-radius: 4px;
          }
        }
      }
    }
  }

  &-page {
    height: inherit;
    // margin: 0 auto;
    // max-width: 1400px;


    &__title {
      margin-bottom: 8px !important;
    }
    &__title-description {
      display: block;
      margin-top: 12px !important;
      margin-bottom: 20px !important;
      padding-left: 10px;
      color: #666;
    }
    &__text {
      margin-right: auto !important;
      &__full-width {
        display: block;
        width: 100%;
      }
    }
    &__tabs {
      justify-content: flex-start;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      overflow: visible !important;
      :first-child {
        overflow: visible !important;
        justify-content: flex-start;
      }
    }
    &__tab {
      display: flex;
      justify-content: center !important;
      color: black !important;
      opacity: 0.6;
      &.Mui-selected {
        opacity: 1 !important;
        color: #1976d2 !important;
      }
    }
    &__tab-content {
      height: inherit;
    }
    &__tab-panel {
      height: inherit;
    }
    &__tab-panel-inner {
      height: inherit;
    }
    &__description {
      margin-bottom: 32px !important;
    }
    &__divider {
      margin: 24px 0 !important;
      &--light {
        margin: inherit;
        height: 0px;
        border-color: rgb(0 0 0 / 0%) !important;
        background-color: rgba(0, 0, 0, 0.0509803922) !important;
      }
    }
    &__box {
      opacity: 1;
      transition: all 200ms ease;
      &--inline {
        display: flex;
        max-width: 800px;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
      }
      &--grid {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        max-width: 600px;
        &-lg {
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          max-width: 800px;
        }
      }
      &--off {
        .settings-page__typography {
          opacity: 0.475;
        }
      }
      .MuiFormControl-root {
        width: inherit !important;
        max-width: 300px;
        min-width: 182px;
      }
      &--flex-start {
        justify-content: flex-start;
      }
    }
    &__toggle-content {
      opacity: 1;

      transition: opacity 200ms ease;
      transition: transform 200ms ease;
      height: fit-content;
      margin-top: 12px;

      ul {
        list-style: none;
        text-decoration: none;
        margin: 0;
        padding: 0;
        li {
          p {
            font-size: 14px !important;
            color: #5b5b5b;
            margin-right: 12px;
          }

          display: flex;
          align-items: center;
          margin-left: 12px;
          justify-content: flex-start;
        }
      }

      &--off {
        transform: translateY(-20px);
        height: 0px;
        opacity: 0;
      }

      &--inline {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-left: 12px;
        width: fit-content;
        gap: 12px;
        p {
          font-size: 14px !important;
          color: #5b5b5b;
        }
      }
    }
    &__card {
      overflow: visible !important;
      margin-bottom: 10px;
      &-content {
        display: flex;
        flex-wrap: wrap;
        padding: 16px !important;
      }
    }
    &__section-toggle {
      $section: ".settings-page__section-toggle";
      opacity: 1;
      margin-top: 10px;
      transition: all 200ms ease;

      &--hidden {
        opacity: 0.2;
      }
    }
    &__text {
      margin-right: 8px !important;
    }
    &__stack {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      p {
        margin-right: 8px;
      }
    }
    &__attribute-box {
      position: relative;
      // z-index: -1;
      width: inherit !important;
      width: 100%;

      input {
        min-width: 150px !important;
      }

      &--skeleton {
        height: 132px !important;
      }
    }
    &__attribute-box,
    &__stack {
      padding: 12px 0;
    }
    .tab-indicator {
      transition: all 200ms ease;
      position: absolute;
      min-width: 95px;
      height: 2px;
      bottom: 0;
      background-color: #1976d2;
    }
    &__accordion {
      z-index: unset;
    }
  }
  &__active-page {
    height: 100%;
    padding: $gutters;
    margin: 0 auto;
    max-width: 1400px;

    @include for-tablet-landscape-up {
      padding: $gutters-desktop;
      position: relative;
    }
  }

  &__button-container--desktop {
    display: none;
    @include for-tablet-landscape-up {
      display: flex;
      position: absolute;
      // top: 52px;
      // right: 52px;
      top: $gutters-desktop;
      right: $gutters-desktop;
      margin: 5.875px 0;
    }
  }
}

@use "./variables.scss" as *;
@use "./colors.scss" as *;

body {
  font-size: 18px !important;
  font-size: clamp(16px, 2.5vmin, 18px) !important;
}
@mixin body {
  font-size: 18px !important;
  font-size: clamp(16px, 2.5vmin, 18px) !important;
}
h1 {
  font-size: 2.5rem !important;
  font-size: clamp(1.75rem, 5vmin, 2.5rem) !important;
}
@mixin h1 {
  font-size: 2.5rem !important;
  font-size: clamp(1.75rem, 5vmin, 2.5rem) !important;
}
h2 {
  font-size: $h2-font-size-small !important;
  font-family: $h2-font-family !important;
  line-height: $h2-line-height-small !important;
  font-weight: $h2-font-weight-small !important;
  letter-spacing: $h2-letter-spacing-small !important;
}
@mixin h2 {
  font-size: $h2-font-size-small !important;
  font-family: $h2-font-family !important;
  line-height: $h2-line-height-small !important;
  font-weight: $h2-font-weight-small !important;
  letter-spacing: $h2-letter-spacing-small !important;
}
h3 {
  font-size: 1.75rem !important;
  font-size: clamp(1.2rem, 3.5vmin, 1.75rem) !important;
}
@mixin h3 {
  font-size: 1.75rem !important;
  font-size: clamp(1.2rem, 3.5vmin, 1.75rem) !important;
}
h4 {
  font-size: 1.5rem !important;
  font-size: clamp(1.1rem, 3vmin, 1.5rem) !important;
}
@mixin h4 {
  font-size: 1.5rem !important;
  font-size: clamp(1.1rem, 3vmin, 1.5rem) !important;
}
h5 {
  font-size: 1.2rem !important;
  font-size: clamp(1rem, 2.5vmin, 1.2rem) !important;
}
@mixin h5 {
  font-size: 1.2rem !important;
  font-size: clamp(1rem, 2.5vmin, 1.2rem) !important;
}
h6 {
  font-size: $h6-font-size-small !important;
  font-family: $h6-font-family !important;
  line-height: $h6-line-height-small !important;
  font-weight: $h6-font-weight-small !important;
  letter-spacing: $h6-letter-spacing-small !important;
}
@mixin h6 {
  font-size: $h6-font-size-small !important;
  font-family: $h6-font-family !important;
  line-height: $h6-line-height-small !important;
  font-weight: $h6-font-weight-small !important;
  letter-spacing: $h6-letter-spacing-small !important;
}

@media (min-width: 1068px) {
  body {
    font-size: 18px !important;
    font-size: clamp(16px, 2.5vmin, 18px) !important;
  }
  h1 {
    font-size: 2.5rem !important;
    font-size: clamp(1.75rem, 5vmin, 2.5rem) !important;
  }
  h2 {
    font-size: $h2-font-size-medium !important;
  }
  h3 {
    font-size: 1.75rem !important;
    font-size: clamp(1.2rem, 3.5vmin, 1.75rem) !important;
  }
  h4 {
    font-size: 1.5rem !important;
    font-size: clamp(1.1rem, 3vmin, 1.5rem) !important;
  }
  h5 {
    font-size: 1.2rem !important;
    font-size: clamp(1rem, 2.5vmin, 1.2rem) !important;
  }
}

@media (min-width: 735px) {
  body {
    font-size: 18px !important;
    font-size: clamp(16px, 2.5vmin, 18px) !important;
  }
  h1 {
    font-size: 2.5rem !important;
    font-size: clamp(1.75rem, 5vmin, 2.5rem) !important;
  }
  h2 {
    font-size: $h2-font-size-medium !important;
  }
  h3 {
    font-size: 1.75rem !important;
    font-size: clamp(1.2rem, 3.5vmin, 1.75rem) !important;
  }
  h4 {
    font-size: 1.5rem !important;
    font-size: clamp(1.1rem, 3vmin, 1.5rem) !important;
  }
  h5 {
    font-size: 1.2rem !important;
    font-size: clamp(1rem, 2.5vmin, 1.2rem) !important;
  }
}

.catch-all {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > *,
  & > * > *,
  & > * > * > *,
  & > * > * > * > * {
    margin: 0;
  }

  .MuiFormControl-root {
    width: inherit !important;
    max-width: unset !important;
    min-width: unset !important;
  }
}

.catch-all__selector-box {
  max-width: fit-content;
  margin-left: 0 !important;
  //   border: 8px solid red !important;
}

.catch-all__selector-box-select {
  //   border: 1px dotted blue !important;
  color: #1976d2 !important;
  height: fit-content;
  margin: 0;
  width: fit-content;
  &--multiple {
    border: 1px dotted pink !important;
    background-color: red !important;
    margin: 0;
    #statuses {
      .MuiBox-root {
        display: flex;
        flex-wrap: wrap;
        max-width: 502px;
      }
    }
  }

  #type {
    padding-right: 20px;
  }
  // Arrow
  .MuiSelect-icon {
    color: #00000026 !important;
  }

  // Underline
  &::before {
    border-bottom: 1px dotted #00000026 !important;
  }
}
.catch-all__selector-box-chip {
  border: 1px dotted black !important;
  background-color: transparent !important;
  span {
    padding: 0;
    padding-right: 4px;
    font-size: 16px;
  }
}
.catch-all__text {
  margin: 0;
  margin-right: 7px !important;
  // width: 100%;
  white-space: nowrap;
  // width: min-content;
  // min-width: fit-content;
  max-width: fit-content;
}
.catch-all__search {
  width: 200px;
  margin-right: 7px;

  .MuiInput-underline {
    &::before {
      border-bottom: 1px dotted #00000026 !important;
    }
  }
}
.catch-all__search-text-field {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: 100%;
}

.catch-all__input-box {
  max-width: 60px;

  &--full-width {
    max-width: fit-content;
    margin-right: 8px;
  }
}
.catch-all__input-box-text-field {
  //   border: 1px solid blue !important;
  height: fit-content;
  color: #1976d2 !important;

  .input-box__input,
  .MuiInput-input {
    color: #1976d2 !important;
    height: auto;
  }

  .input-box__text-field__box {
    &::before {
      border-bottom: 1px dotted #00000026 !important;
      bottom: -3px;
    }
    &::after {
      bottom: -3px;
    }
  }
}

/* Import the Open Sans font */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

.video-resources {
  font-family: "Open Sans", sans-serif;
  background-color: #f7f8fa;
  padding: 20px;
}







.paywell-logo {
  height: 40px;
  width: auto;
}

h1 {
  font-size: 36px;
  color: #292e35;
  margin: 0;
  font-weight: 700;
}

.search-bar {
  margin: 20px 0 40px 0;
}

.browse-header {
  text-align: center;
  margin: 60px 0 30px 0;
}

.browse-header h3 {
  font-size: 28px;
  color: #292e35;
  margin: 0;
}

.browse-header p {
  font-size: 18px;
  color: #4a4f55;
  margin-top: 8px;
}

.resources-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  max-width: 1140px; /* Align resource cards with content */
  margin-left: auto;
  margin-right: auto;
}


.resource-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.resource-card {
  text-decoration: none !important;
  color: inherit;
}

.resource-card h4 {
  color: #292e35;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.resource-card p {
  color: #4a4f55;
  font-size: 14px;
  margin-top: 10px;
}

.resource-icon {
  font-size: 48px;
  color: #13C296;
}

@media (max-width: 768px) {
  .resource-card {
    width: 100%;
  }
}

.search-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .search-input {
    width: 100%;
    max-width: 500px;
    padding: 12px 20px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 30px 0 0 30px;
    outline: none;
    transition: box-shadow 0.3s ease;
  }
  
  .search-input:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-color: #13C296;
  }
  
  .search-button {
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 0 30px 30px 0;
    background-color: #13C296;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #13C296;
  }
  
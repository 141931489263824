@use "../../Styles/variables.scss" as *;

.settings-page {
  &__input-container {
    opacity: 1;
    transition: all 200ms ease;
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  &__gray-out {
    opacity: .65;
  }
  .mw-200 {
    min-width: 200px;
  }
  .w-max-400 {
    max-width: 400px;
  }
  .w-full {
    width: 100%;
  }
  .MuiTooltip-tooltip {
    font-size: 1em !important;
  }
}

.tooltip-paragraph {
  margin: 0;
  margin-top: 5px;
}
@mixin for-landscape-up {
  @media (min-width: 1025px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1300px) {
    @content;
  }
}
$disabledOpacity: 0.7;
$disabledColor: #cccccc;
$completeColor: #15c297;
.stepper {
  width: 100%;
  $padding: 0 15px;
  align-items: center;
  @include for-landscape-up {
    $padding: 0 30px;
  }
  display: flex;
  flex-direction: column;

  &__h3,
  &__subtitle {
    text-align: center;
  }
  &__subtitle {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__label {
    $labelIcon: ".stepper__label-icon";

    #{$labelIcon} {
      position: relative;
      display: flex;

      width: 38px;
      height: 38px;
      justify-content: center;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 20px;
        z-index: 1;
        color: $completeColor;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        border-radius: 999px;
        width: 100%;
        height: 100%;
        border: 1px solid $completeColor;
      }
    }

    // Label Icon Modifiers
    .Mui-active {
      #{$labelIcon} {
        .MuiSvgIcon-root {
          color: $completeColor !important;
        }
      }
    }
    &.Mui-disabled {
      #{$labelIcon} {
        .MuiSvgIcon-root {
          opacity: $disabledOpacity;
          color: $disabledColor;
        }
        &::after {
          opacity: $disabledOpacity;
          border: 1px solid $disabledColor;
        }
      }
    }

    // Label Text Modifiers
    .MuiStepLabel-label {
      &.Mui-active {
      }
      &.Mui-disabled {
        color: $disabledColor;
      }
      &.Mui-completed {
        font-weight: 400;
      }
    }

    &-text {
      white-space: nowrap;
    }
  }

  &__content {
    width: 100%;

    align-self: center;
    padding: $padding;
    display: flex;
    justify-content: center;

    .css-1gsv261 {
      width: fit-content;
    }
    .MuiTabs-flexContainer {
      width: fit-content;
      justify-content: center;
    }
    &-options {
    }

    margin-top: 75px;
    &--2 {
      margin-top: 0;
    }
  }

  &__header {
    align-self: flex-end;
    width: 100%;
    @include for-landscape-up {
      max-width: none;
    }
  }

  &__step {
    padding: 16px !important;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background-color: white;
    box-shadow: rgb(0 0 0 / 4%) 0px -4px 4px;
    max-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .MuiStepConnector-root {
      top: 30px !important;
      left: calc(-35% + 30px);
      right: calc(65% + 30px);
      .MuiStepConnector-line {
        border-color: $completeColor;
      }
      &.Mui-disabled {
        opacity: $disabledOpacity;

        .MuiStepConnector-line {
          border-color: $disabledColor;
        }
      }
    }

    &--active {
      border-top: 3px solid #15c29773;
      z-index: 1;
    }
    &--complete {
      border-top: 3px solid #15c29773;
      .stepper__label {
        .MuiStepLabel-iconContainer {
          padding: 0px;
        }

        // Label Text
        &-text,
        .MuiStepLabel-labelContainer {
          opacity: 0;
          width: 0px;
        }
      }
    }
    &--disabled {
      border-top: 3px solid #e3e3e3;
      // transform: translateY(10px);
      // transform: translateY(10px);
      // padding-top: 10px !important;
      .stepper__label {
        .MuiStepLabel-iconContainer {
          padding: 0px;
        }

        // Label Text
        &-text,
        .MuiStepLabel-labelContainer {
          opacity: 0;
          width: 0px;
        }
      }
    }

    @include for-landscape-up {
      // height: 130px;
      // max-height: 130px;

      &--active {
        border-top: 3px solid #15c29773;
        z-index: 1;
      }
      &--complete {
        border-top: 3px solid #15c29773;
        .stepper__label {
          .MuiStepLabel-iconContainer {
            padding-right: 8px;
          }

          // Label Text
          &-text,
          .MuiStepLabel-labelContainer {
            opacity: 1;
            width: inherit;
          }
        }
      }
      &--disabled {
        border-top: 3px solid #e3e3e3;
        // transform: translateY(10px);
        // transform: translateY(10px);
        // padding-top: 10px !important;
        .stepper__label {
          .MuiStepLabel-iconContainer {
            padding-right: 8px;
          }

          // Label Text
          &-text,
          .MuiStepLabel-labelContainer {
            opacity: 1;
            width: inherit;
          }
        }
      }
    }
  }
  &__actions {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include for-landscape-up {
      flex-direction: row;
    }
    @include for-desktop-up() {
    }

    // box-shadow: rgb(0 0 0 / 4%) 0px -4px 4px;
    &-inner {
      z-index: 1;
      height: 60px;
      max-width: 1200px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: white;
      box-shadow: rgb(0 0 0 / 4%) 0px -4px 4px;
      align-items: center;
      padding: $padding;
      @include for-landscape-up {
        height: 110px;
        max-width: none;
        width: fit-content;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 156px;
    height: 45px;

    font-size: 1rem !important;
    padding: 12px 24px;
    margin: 0px 4px !important;
    flex: 1 0 auto;
    &-icon {
      margin-bottom: 2px;
      font-size: 20px !important;
    }
    &--download {
      // width: 100%;
      max-width: none;
    }

    @include for-landscape-up {
      height: 68px;
      max-width: 116px;
      &--download {
        width: fit-content;
        max-width: none;
      }
    }
  }

  // Remove Connector Line from stepper
  .MuiStepConnector-root {
    display: none;
  }
}

@use "../../Styles/variables.scss" as *;
@use "../../Styles/colors.scss" as *;
@use "../../Styles/mixins" as mixins;

/*** Tooltip ***/
.notification-tooltip {
  display: none;
  max-width: 500px;

  @include mixins.for-tablet-landscape-up {
    display: block;
  }
}

// .MuiPopper-arrow {
//   width: 2em;
//   height: 2em;
//   position: absolute;
//   font-size: 7px;
//   background: inherit;
//   color: inherit;
//   margin: 0 auto;
//   display: block;
//   z-index: 1;
//   top: -1em;
// }

// .MuiPopper-arrow::before {
//   content: '';
//   display: block;
//   width: 100%;
//   height: 100%;
//   background-color: inherit;
//   // box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.1);
//   box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.07);
//   // box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.022);
//   background-color: $color-green !important;
//   border-right: 0;
//   border-bottom: 0;
//   transform: rotate(45deg);
// }
// .MuiPopper-arrow {
//   width: 2em;
//   height: 2em;
//   position: absolute;
//   font-size: 7px;
//   background: inherit;
//   color: inherit;
//   margin: 0 auto;
//   display: block;
//   z-index: 1;
//   top: -1em;
//   left: 50%;
//   transform: translateX(-50%);
//   transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
// }

// .MuiPopper-arrow::before {
//   content: '';
//   display: block;
//   width: 100%;
//   height: 100%;
//   background-color: inherit;
//   box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.07);
//   background-color: $color-green !important;
//   border-right: 0;
//   border-bottom: 0;
//   transform: rotate(45deg);
// }

.MuiPopper-arrow {
  width: 2em;
  height: 2em;
  position: absolute;
  font-size: 7px;
  background: inherit;
  color: inherit;
  margin: 0 auto;
  display: block;
  z-index: 1;
  top: -1em;
  left: 50%;
}


.MuiPopper-arrow::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: inherit;
  box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.07);
  background-color: $color-green !important;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}
.notification-tooltip__paper {
  background-color: $color-green !important;
  // background-color: #f5f5f5;
  position: relative;
  z-index: 0;
}

.notification-tooltip__title {
  font-size: 20px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 600 !important;
  color: white;
}

.notification-tooltip__message {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  color: white !important;

}




/*** Popup ***/
.notification-popup__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 4% 6% 6% 6% !important;

  @include mixins.for-tablet-portrait-up-lg {
    flex-direction: row;
    align-items: center;
  }
}

.notification-popup__image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 16px;

  @include mixins.for-tablet-portrait-up-lg {
    max-width: 30%;
    max-height: none;
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.notification-popup__text {
  flex: 1;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}




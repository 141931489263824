.multi-select-chip,
.skeleton--multi-select-chip {
  // margin-top: 78px;
}

.multi-select-chip--error {
  .multi-select-chip__input-label {
    color: #d32f2f;
  }
  .multi-select-chip__select {
    // border: 1px solid #d32f2f;
    .MuiOutlinedInput-notchedOutline {
      border-color: #d32f2f;
    }
  }
  .multi-select-chip__helper-text {
    color: #d32f2f;
  }
}

.skeleton--multi-select-chip {
  width: 442px;
  height: 65px;
  transform: none !important;
}

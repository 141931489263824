.deleteDialog {
    p {
        margin-top: 8px;
        margin-bottom: 20px;
    }
    .warningText {
        color: #f44336;
        font-size: .86em;
    }
}

.createDialog {
  p {
    font-size: .86em;
  }
  .warningText {
    color: #947100;
    font-size: 1em;
  }
}

.inputContainer {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.addButton {
  height: 56px; // Match MUI TextField default height
}

.biggerText {
  font-size: 1.2em;
}

.locationName {
  color: #555;
  margin: 0;
  padding: 0;
  svg {
    font-size: 1.3em;
    margin-top: -6px;
  }
}

   /* .resource-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e0e4e8;
    border-radius: 8px;
    width: 220px;
    padding: 20px;
    text-align: center;
    color: #333333;
    text-decoration: none;
    transition: box-shadow 0.2s ease;
  }
  
  .resource-card:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .resource-icon img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .resource-content h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .resource-content p {
    font-size: 14px;
    color: #4a4f55;
  }
   */

   .resource-card {
    transition: box-shadow 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: center;
    width: 30%;
    max-width: 420px;
    min-height: 340px;
    padding: 30px 30px 40px 30px;
    margin: 0;
    cursor: pointer;
    border-radius: 13px;
    height: auto;
    justify-content: flex-start;
    /* gap: 20px; */
    flex-direction: column;
    display: flex;
    background-color: #f7f8fa;
    border: none;
    text-decoration: none !important;
    color: inherit !important; /* Inherit text color */

  }
  
  .resource-card:hover {
    box-shadow: 0px 0px 29px 0px #9B9BB65C;  }
  
  .resource-card {
    text-decoration: none;
    color: inherit;
  }
  
  .resource-card h4 {
    color: #292e35;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 600;
  }
  
  .resource-card p {
    color: #4a4f55;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .resource-icon {
    font-size: 64px;
    color: #13C296;
    /* margin-bottom: 20px; */
  }
  
.monday-form {
}
.monday-form__form {
}

.monday-form__button {
  position: relative !important;
  margin-left: auto !important;
  margin-top: 12px !important;
}

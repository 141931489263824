.modal-popup {
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  padding: 50px;
  border-radius: 8px;
  overflow-y: scroll;

  &__button {
    position: absolute !important;
    top: 5px;
    right: 5px;
  }
  &--video {
    overflow: visible;
    width: fit-content;
    height: fit-content;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    transform: none;
    border-radius: 0;
    margin: auto;
    background-color: transparent;

    .modal-popup__button {
      display: none;
    }

    iframe {
      height: 70vh !important;
      min-height: unset !important;
    }
  }
  &--referral {
    overflow-y: hidden !important;
    top: 45%;
    width: 26%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__h5 {
    text-align: center;
    display: flex;
    flex-direction: column;
    span {
      margin: 8px 0;
    }
  }
  &__background-image {
    background-image: url("../../img/referralGift.webp");
    background-size: contain;
    background-repeat: no-repeat;
    flex: 1;
    width: 60%;
    max-height: 269px;
    left: -20px;
    position: relative;
  }
}

// Unadded Staff Dialog
.unadded-staff-dialog .MuiPaper-root {
  max-width: 900px !important;
  width: 100%;
}

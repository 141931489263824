@use "../../Styles/variables.scss" as *;
@use "../../Styles/mixins" as mixins;
@use "../../Styles/typography.scss" as *;

.privacy-policy {
}
.privacy-policy__title {
  padding-top: 40px;
  padding-bottom: 60px;

  @include h2;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 42px !important;
  line-height: 100% !important;
  font-weight: 900 !important;
  letter-spacing: -0.01em !important;
  text-align: left !important;
  // max-width: 450px !important;
  max-width: 330px !important;
}
.privacy-policy__subheader,
.privacy-policy__body {
 
  margin-bottom: 30px !important;
  letter-spacing: -0.01em;
  text-align: left;
  color: #101010;
  line-height: 2;
}
.privacy-policy__subheader {
  @include body;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
}
.privacy-policy__body {
  @include body;
  letter-spacing: -0.05rem;
  line-height: 140%;
  font-weight: 300 !important;
  font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica" !important;;

  // font-size: 12px !important;
  // font-size: 0.75rem;
}

.privacy-policy__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@include mixins.for-tablet-landscape-up {
  .privacy-policy__title {
    font-size: 4rem !important;
    line-height: 100% !important;
    font-weight: 900 !important;
    letter-spacing: -0.01em !important;
    text-align: left !important;
    max-width: 450px !important;
  }
}

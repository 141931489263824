// .custom-drawer {
// }

// .custom-drawer__box {
//   margin-top: 57px;
//   height: 100%;
//   padding: 20px 16px;
// }
// .custom-drawer__content {
//   height: 100%;
// }
// .custom-drawer__header-container {
//   margin-bottom: 14px;
// }

// .custom-drawer__title {
//   color: #212529;
//   font-weight: 300 !important;
// }
// .custom-drawer__close-button {
// }



.custom-drawer {

}

.custom-drawer__box {
  margin-top: 57px;
  height: 100%;
  padding: 20px 16px;

  width: 100%;
  max-width: 100%; /* Full width on mobile devices */

  @media (min-width: 800px) {
    width: 605px; /* Default max width for larger screens */
  }
}

.custom-drawer__content {

}

.custom-drawer__header-container {
  margin-bottom: 14px;
}

.custom-drawer__title {
  color: #212529;
  font-weight: 300 !important;
}

.custom-drawer__close-button {
}

.input-box {
  $b: ".input-box";
  &__text-field {
    height: 44px;
    :focus,
    :focus-visible {
      outline: none;
    }
    label {
      z-index: 1 !important;
    }
    #{$b}__input {
      outline-color: #006bff;
      margin-right: 4px;
      border: transparent;
      max-width: 35px;
      height: 44px;
      background-color: transparent;
    }
    &__box {
      padding: 0px 0 0px 0px !important;
      border: none;
    }
    #{$b}__button-group {
      //   border: 1px solid blue;
    }
    #{$b}__button {
      padding: 5px !important;
    }
  }
}

.selector-box {
  max-width: fit-content;
  margin-left: 0 !important;

  &__label {
  }
  &__select {
    height: 44px;
    margin: 0 10px;
    &--multiple {
      margin: 0;
      #statuses {
        .MuiBox-root {
          display: flex;
          flex-wrap: wrap;
          max-width: 502px;
        }
      }
    }
  }
  &__chip {
    background-color: transparent !important;
    span {
      padding: 0;
      padding-right: 4px;
      font-size: 16px;
    }
  }

  &--exceptions {
    @extend .selector-box;
    $b: ".selector-box";
    border: 1px solid red !important;

    #{$b}__label {
    }
    #{$b}__select {
      border: 1px dotted blue !important;
      color: #1976d2;
      height: fit-content;
      margin: 0;
      width: fit-content;
      &--multiple {
        border: 1px dotted pink !important;
        background-color: red !important;
        margin: 0;
        #statuses {
          .MuiBox-root {
            display: flex;
            flex-wrap: wrap;
            max-width: 502px;
          }
        }
      }

      .MuiSelect-icon {
        color: inherit;
      }
    }
    #{$b}__chip {
      border: 1px dotted black !important;
      background-color: transparent !important;
      span {
        padding: 0;
        padding-right: 4px;
        font-size: 16px;
      }
    }
  }
}


.multiple-select {
  max-width: unset;
  width: 95% !important;
}
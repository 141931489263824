// /* ---------------------------------------
//    1) Base Resets (inspired by your HBS)
// --------------------------------------- */
// .email-container {
//   width: 100%;
//   margin: 0 auto;
//   background-color: #ffffff;
//   border: 1px solid #e6e6e6;
//   border-radius: 6px;
//   font-family: "Helvetica Neue", Arial, sans-serif;
//   line-height: 1.5em;
//   color: #515151;
//   /* darker than #525257 for slightly better contrast */
// }

// /* Header Logo */
// .header-logo {
//   text-align: center;
//   padding: 40px 0;

//   img {
//     max-width: 120px;
//     height: auto;
//     display: inline-block;
//   }
// }

// /* Title Section (Payday Greeting, etc.) */
// .title-section {
//   padding: 24px 30px;
//   /* giving some left-right padding to match Gusto-like spacing */
//   text-align: center;
//   border-bottom: 1px solid #ebebeb;

//   /* subtle divider line at bottom */
//   .company-name {
//     font-size: 16px;
//     color: #7a7a7a;
//     margin-bottom: 8px;
//     color: #525257;
//     font-style: normal;
//     font-weight: 400;
//     letter-spacing: normal;

//     font-family: GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
//   }

//   .main-heading {
//     font-size: 30px;
//     font-weight: 700;
//     margin: 0;
//     color: #333333;
//     line-height: 1.3em;



//     font-size: 44px;
//     font-weight: 700;
//     line-height: 49px;
//     margin-top: 0;
//     margin-right: 0;
//     margin-left: 0;
//     color: #525257;
//     font-family: clearface, Times, serif;
//     margin-bottom: 0;
//   }
// }

// /* Pay summary block */
// .pay-summary {
//   margin: 0 15px 15px 15px;
//   /* aligned horizontally with subheader spacing */
//   padding: 20px 25px;
//   background-color: #ffffff;
//   border: 1px solid #dcdcdc;
//   border-radius: 6px;

//   .pay-summary-header {
//     font-size: 20px !important;
//     text-align: center;
//     padding-top: 0;
//     padding-right: 0;
//     padding-bottom: 0;
//     padding-left: 0;
//     color: #525257;
//     font-style: normal;
//     font-weight: 400;
//     letter-spacing: normal;
//     margin-top: 0;
//     margin-right: 0;
//     margin-bottom: 0;
//     margin-left: 0;
//     font-family: GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
//   }

//   .pay-check-amount {
//     font-size: 28px;
//     font-weight: 700;
//     line-height: normal;
//     margin-top: 0;
//     margin-right: 0;
//     margin-left: 0;
//     margin-bottom: 0;

//     color: #525257;
//     font-family: GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
//   }

//   .pay-period-dates {
//     font-size: 20px;
//     line-height: 1.6em;
//     margin-top: 0;
//     margin-right: 0;
//     margin-left: 0;
//     margin-bottom: 0;
//     font-weight: 400;
//   }


//   .pay-summary__text-label {
//     color: #6c6c72;
//     font-size: 14px;
//     font-weight: 400;
//     margin-top: 0;
//     margin-right: 0;
//     margin-bottom: 5px;
//     margin-left: 0;
//     font-family: GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
//   }

//   .pay-summary__text {
//     font-size: 28px;
//     font-weight: 700;
//     line-height: normal;
//     margin-top: 0;
//     margin-right: 0;
//     margin-left: 0;
//     margin-bottom: 0;

//     color: #525257;
//     font-family: GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

//   }
//   .pay-check-amount-label,
//   .pay-period-dates-label {
//     color: #6c6c72;
//     font-size: 14px;
//     font-weight: 400;
//     margin-top: 0;
//     margin-right: 0;
//     margin-bottom: 5px;
//     margin-left: 0;
//     font-family: GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
//   }

//   p {
//     font-size: 15px;
//     margin-top: 1em;
//     margin-bottom: 0;
//     color: #515151;
//   }

//   table {
//     width: 100%;
//     margin-top: 0.5rem;

//     td {

//       &:first-of-type {
//         padding-right: 2rem;
//         /* space between left & right columns */
//       }
//     }
//   }
// }

// /* Custom Email Text Content */
// .custom-email-text {
//   font-size: 15px;
//   line-height: 1.6em;
//   margin: 15px;
//   word-wrap: break-word;
//   color: #515151;
// }

// /* Subheader outside the table, for each section */
// .subheader-section-title {
//   font-size: 18px;
//   color: #525257;
//   /* accent color for subheader */
//   margin: 0 15px 8px 15px;
//   font-weight: 700;
//   line-height: 1.4em;


//   font-size: 30px;
//   font-weight: 700;
//   color: #333333;
//   line-height: 1.3em;



//   font-size: 44px;
//   font-weight: 700;
//   line-height: 49px;
//   color: #525257;
//   font-family: clearface, Times, serif;
// }

// /* Table area */
// .table-wrapper {
//   margin: 0 15px 25px 15px;
//   border: 1px solid #dcdcdc;
//   border-radius: 6px;
//   overflow: hidden;
//   background-color: #fff;
// }

// .table-container {
//   width: 100%;
//   overflow-x: auto;
//   /* subtle top border or keep as is if you prefer a clean look */
// }

// .paystub-table {
//   width: 100%;
//   border-collapse: collapse;
//   table-layout: auto;

//   th,
//   td {
//     font-size: 14px;
//     border: 1px solid #ebebeb;
//     padding: 12px 10px;
//     text-align: left;
//     word-break: break-word;
//     min-width: 100px;
//     vertical-align: middle;
//     /* center text vertically */
//     color: #515151;
//   }

//   /* Zebra stripes on even rows */
//   tbody tr:nth-child(even) {
//     background-color: #fafafa;
//   }
// }

// /* Totals row styling */
// .totals-row {
//   background-color: #f9f9f9;
//   font-weight: 700;
//   color: #515151;
//   border-bottom: 1px solid #ebebeb;
// }

// /* Potential sub-subheader row (if needed) */
// .subheader-row {
//   background-color: #f2f2f2;
//   font-weight: 600;
//   font-size: 15px;
//   color: #444;
//   border-bottom: 1px solid #e2e2e2;

//   td,
//   th {
//     border: none !important;
//   }
// }

// /* Footer block */
// .footer-block {
//   text-align: center;
//   font-size: 14px;
//   color: #6c6c72;
//   padding: 20px;
//   line-height: 1.6em;
//   border-top: 1px solid #ebebeb;
//   /* subtle top border for separation */
// }

// /* Hero image styling (if used) */
// .hero-image {
//   text-align: center;

//   img {
//     max-width: 600px;
//     width: 100%;
//     display: block;
//     margin: 0 auto;
//     border-radius: 4px;
//   }
// }

// /* ---------------------------------------
//    2) Modal Layout Classes
// --------------------------------------- */
// .paywell-preview-modal-container {
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
//   overflow-y: auto;
//   padding: 2rem 0;
// }

// .paywell-preview-modal-content {
//   background-color: #fff;
//   border-radius: 6px;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
//   width: 100%;
//   max-width: 800px;
//   margin: 1rem;
//   display: flex;
//   flex-direction: column;
// }

// /* A simple top bar to close the modal, etc. */
// .paywell-preview-header-bar {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background-color: #f1f3f4;
//   padding: 0.75rem 1rem;
//   border-bottom: 1px solid #dadce0;

//   .paywell-preview-header-title {
//     font-weight: 600;
//     font-size: 1.2rem;
//   }

//   .paywell-preview-close-button {
//     margin-left: auto;
//   }
// }

// /* A container for subject info, date, etc. */
// .paywell-preview-info {
//   padding: 1rem;
// }

// .paywell-preview-subject {
//   margin-bottom: 0.5rem;
//   font-weight: 700;
//   font-size: 1.25rem;
// }

// .paywell-preview-meta {
//   display: flex;
//   align-items: center;
//   gap: 1rem;

//   .paywell-preview-meta-details {
//     display: flex;
//     flex-direction: column;
//     line-height: 1.2;
//   }

//   .paywell-preview-date {
//     margin-left: auto;
//     font-size: 0.8rem;
//     color: #999;
//   }
// }

// /* The actual "email body" portion, which includes .email-container. */
// .paywell-preview-body {
//   padding: 1rem;
// }


/* ---------------------------------------
   1) Base Resets
--------------------------------------- */
.email-container {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  line-height: 1.5em;
  color: #515151;
}

/* Header Logo */
.header-logo {
  text-align: center;
  padding: 40px 0;

  img {
    max-width: 120px;
    height: auto;
    display: inline-block;
  }
}

/* Title Section */
.title-section {
  padding: 24px 30px;
  text-align: center;
  border-bottom: 1px solid #ebebeb;

  .company-name {
    font-size: 16px;
    color: #525257;
    margin-bottom: 8px;
    font-weight: 400;
    letter-spacing: normal;
    font-family: GCentra, -apple-system, BlinkMacSystemFont, 
      Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
  }

  .main-heading {
    font-size: 44px;
    font-weight: 700;
    line-height: 49px;
    margin: 0;
    color: #525257;
    font-family: clearface, Times, serif;
  }
}

/* Pay Summary Block */
.pay-summary {
  margin: 0 15px 15px 15px;
  padding: 20px 25px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;

  .pay-summary-header {
    font-size: 20px !important;
    text-align: center;
    color: #525257;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 400;
    font-family: GCentra, -apple-system, BlinkMacSystemFont, 
      Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
  }

  .pay-check-amount {
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    color: #525257;
  }

  .pay-period-dates {
    font-size: 20px;
    line-height: 1.6em;
    margin: 0;
    font-weight: 400;
  }

  .pay-summary__text-label {
    color: #6c6c72;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 5px 0;
    font-family: GCentra, -apple-system, BlinkMacSystemFont, 
      Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  }

  .pay-summary__text {
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    color: #525257;
  }

  table {
    width: 100%;
    margin-top: 0.5rem;

    td {
      vertical-align: top;

      &:first-of-type {
        padding-right: 2rem;
      }
    }
  }
}

/* Custom Email Text Content */
.custom-email-text {
  font-size: 15px;
  line-height: 1.6em;
  margin: 15px;
  word-wrap: break-word;
  color: #515151;
}

/* Subheader Title for Each Section */
.subheader-section-title {
  font-size: 44px;
  font-weight: 700;
  line-height: 49px;
  color: #525257;
  font-family: clearface, Times, serif;
  margin: 0 15px 8px 15px;
  text-align: start;
}

/* Table Wrapper */
.table-wrapper {
  margin: 0 15px 25px 15px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.paystub-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    font-size: 14px;
    border: 1px solid #ebebeb;
    padding: 12px 10px;
    text-align: left;
    word-break: break-word;
    min-width: 100px;
    vertical-align: middle;
    color: #515151;
  }

  tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
}

/* Totals Row */
.totals-row {
  background-color: #f9f9f9;
  font-weight: 700;
  color: #515151;
  border-bottom: 1px solid #ebebeb;
}

/* Subheader Row Inside Table */
.subheader-row {
  background-color: #f2f2f2;
  font-weight: 600;
  font-size: 15px;
  color: #444;
  border-bottom: 1px solid #e2e2e2;

  td,
  th {
    border: none !important;
  }
}

/* Footer Block */
.footer-block {
  text-align: center;
  font-size: 14px;
  color: #6c6c72;
  padding: 20px;
  line-height: 1.6em;
  border-top: 1px solid #ebebeb;
}

/* (Optional) Hero Image */
.hero-image {
  text-align: center;

  img {
    max-width: 600px;
    width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 4px;
  }
}

/* ---------------------------------------
   2) Modal Layout Classes (optional)
--------------------------------------- */
.paywell-preview-modal-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  padding: 2rem 0;
}
.paywell-preview-modal-content {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 800px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}
.paywell-preview-header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f3f4;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #dadce0;

  .paywell-preview-header-title {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .paywell-preview-close-button {
    margin-left: auto;
  }
}
.paywell-preview-info {
  padding: 1rem;
}
.paywell-preview-subject {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.25rem;
}
.paywell-preview-meta {
  display: flex;
  align-items: center;
  gap: 1rem;

  .paywell-preview-meta-details {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
  }
  .paywell-preview-date {
    margin-left: auto;
    font-size: 0.8rem;
    color: #999;
  }
}
.paywell-preview-body {
  padding: 1rem;
}

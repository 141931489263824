@mixin breakpoint($point) {
  @if $point == retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      @content;
    }
  }
  // 16px baseline (256px) s = small
  @else if $point == s {
    @media (min-width: 256px) {
      @content;
    }
  }
  // 16px baseline (580px) sw = small-wide
  @else if $point == sw {
    @media (min-width: 580px) {
      @content;
    }
  }

  // 16px baseline (768px) m = medium
  @else if $point == m {
    @media (min-width: 768px) {
      @content;
    }
  }

  // 16px baseline (960px) l = large
  @else if $point == l {
    @media (min-width: 960px) {
      @content;
    }
  }
  // 16px baseline (1024px) lg = large
  @else if $point == lg {
    @media (min-width: 1024px) {
      @content;
    }
  }
  // 16px baseline (1152px) lx = extra large
  @else if $point == xl {
    @media (min-width: 1152px) {
      @content;
    }
  } @else if $point == xl-plus {
    @media (min-width: 1496px) {
      @content;
    }
  }

  // 16px baseline (1024px) lg = large
  @else if $point == xxl {
    @media (min-width: 1700px) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}

.referrals {
  position: relative;
  padding: 15px;
  // border: 1px solid red;
  margin: 0 auto;
  max-width: 1320px;
  width: 95%;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    // border: 1px solid green;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    // max-width: 972px;

    @include breakpoint(l) {
      flex-direction: row;
    }
  }

  &__h4 {
    font-weight: 500 !important;
    margin-bottom: 40px !important;
    // align-self: flex-start;
  }
  &__h6 {
    font-size: 24px !important;
    &:last-of-type {
      // margin-top: 16px !important;
    }
  }
  &__card {
    width: 100%;
    &-content {
      padding: 40px !important;
    }
  }
  &__section {
    width: 100%;
    max-width: 420px;
  }
  &__form {
    display: flex;
    flex-wrap: wrap;

    &-field {
      width: 100% !important;
      margin: 8px 0 !important;

      .valid {
        background-color: #3abf9c2f;
      }
      .invalid {
        background-color: #ff00001f;
      }
    }

    &--col {
      flex-direction: column;
    }
    &--flex-start {
      justify-content: flex-start !important;
    }

    @include breakpoint(l) {
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 12px;
      align-items: center;

      &--col {
        gap: 0px;
        flex-direction: column;
      }
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    &-item {
      display: flex;
      align-items: center;
      padding: 30px 0;

      &-icon {
        padding: 0 25px;
        svg {
          color: #3abf9c;
          font-size: 50px;
        }
      }

      &--border {
        // margin: 30px 0;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
      }
    }
  }

  &__button {
    margin: 0 !important;
    width: 100% !important;
    height: 52px;
    &--copy {
      @include breakpoint(l) {
        max-width: 140px;
        height: 52px;
      }
    }
    &--secondary {
      line-height: unset !important;
      display: flex;
      align-items: center !important;
      justify-content: flex-start !important;
      margin-bottom: 25px !important;
    }
  }
}

.tabs-container {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tabs {
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .MuiTabs-flexContainer {
    justify-content: center;
  }
}
.tab-panels {
  width: 100%;
  padding-top: 24px;
}
.tab-panel {
  // width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  gap: 20px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(320px, 335px));

  // &--0 {
  //   grid-template-columns: none;
  //   width: 100%;
  //   border: 1px solid yellow;
  // }
}

.clubready-scrape {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: 50px;
}
.clubready-scrape__form {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 20px;

  &--loading {
    // display: inline-block;
    // height: 1em;
    position: relative;
    overflow: hidden;
    // background-color: #dddbdd;

    opacity: 0.5;
    &::after {
      padding: 20px;
      margin: 20px;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: "";
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
.clubready-scrape__date-picker-container {
  flex: 2;
}
.clubready-scrape__date-picker-label {
}
.clubready-scrape__date-picker {
}
.date-range-picker__box {
  width: 100%;
}
.date-range-picker__input-container {
  width: 100%;
}
.clubready-scrape__button {
  height: 100%;
  max-height: 56px;
  min-width: 138px !important;
  flex: 1;
}

.clubready-scrape__date-input {
  display: flex;
  opacity: 1;
  transition: all 0.6 ease;
}

.clubready-scrape__status {
  width: 100%;
}
.clubready-scrape__errors-container {
  // margin-bottom: 10px;
}
.clubready-scrape__error {
  margin: 10px 0;
}
.clubready-scrape__error span {
  margin: 0 6px;
}
.clubready-scrape__error .MuiAlert-message {
  // display: flex;
  // flex-wrap: wrap;
  // column-gap: 4px;
}

.dynamic-table-container {
  margin-top: 16px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 4px;
}
// .dynamic-table-container th, .dynamic-table-container td {
//   padding: 8px 16px 8px 16px;
// }
// .dynamic-table-container thead th{
//   padding: 12px 16px 12px 16px;
// }

.disabled {
  opacity: 0.5;
}
.openClubReadyAccountsButton {
  border-radius: 4px !important;
  border: 1px solid #1976d2 !important;
  color: #1976d2 !important;
  // padding: 7px 21px !important;
  padding-left: 21px !important;
  padding-right: 21px !important;
  text-transform: uppercase;
  width: max-content;
  min-width: 216px;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  height: 56px;
  .MuiListItemText-root {
    flex: none !important;
  }
  span {
    text-align: center;
    width: fit-content;
    font-weight: 500;
  }
}
@media screen and (min-width: 950px) {
  .clubready-scrape {
    justify-content: center;
    // flex-direction: row;
    max-width: 854px;
  }
  .clubready-scrape__form {
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    column-gap: 20px;
    justify-content: space-between;
  }
  .clubready-scrape__button {
    // position: relative;
    // top: 0;
    // bottom: 0;
    // margin: auto 0;
  }
}

/* Import the Open Sans font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

/* Scope styles within the .resources class */
.resources {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #333333;
  background-color: #f7f8fa;
}

.resources a {
  text-decoration: none;
  color: #1F76C2 !important;
}

.resources h1,
.resources h2,
.resources h3,
.resources h4,
.resources h5,
.resources h6 {
  color: #333333;
  margin-top: 0;
}

.resources .drawer__back-button {
  position: relative;
  margin-right: auto !important;
  padding: 0 0 0 15px !important;
  margin-left: 0 !important;
}

.resources .header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Changed from center to flex-start */
  /* margin-bottom: 20px; */
  max-width: 1140px; /* Align with content */
  margin-left: auto;
  margin-right: auto;
  gap: 60px;
}

.resources .title-container {
    flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  align-self: center; /* Center the title-container within header-container */
}
.resources .title-container .resources__h1 {
font-size: 60px !important;
color: #292e35;
opacity: 80%;
}
.resources .search-bar {
  margin: 20px 0 40px 0;
  max-width: 1140px; /* Align search bar with content */
  margin-left: auto;
  margin-right: auto;
}

.resources .resources__h2 {
  font-size: 18px !important;
  line-height: 42px !important;
  margin-bottom: 24px !important;
}

.resources .category-page p,
.resources .resource-card p,
.resources .video-resources p {
  line-height: 1.6;
  color: #676879 !important;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300;
}

.resources .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}











/* Existing styles for resources-list and resource-card */




.resources .resource-icon {
  font-size: 48px;
  /* color: #00c875; */
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
  /* For screens smaller than 992px, adjust the width of the cards */
  .resources .resource-card {
    width: 45%;
  }
}

@media (max-width: 768px) {
    .resources .title-container {
        flex-direction: column;
    }
    .resources .title-container .resources__h1 {
        font-size: 30px !important;
        }
  /* For screens smaller than 768px, stack the cards vertically */
  .resources .resources-list {
    flex-direction: column;
    align-items: center;
  }

  .resources .resource-card {
    width: 100%;
  }

  /* Adjust padding for header and search bar */
  .resources .header-container,
  .resources .search-bar {
    padding: 0 20px;
  }
}

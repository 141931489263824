////////// Header 2 //////////
$h2-font-size-small: 32px;
$h2-line-height-small: 1.125;
$h2-font-weight-small: 600;
$h2-letter-spacing-small: 0.004em;
$h2-font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif;

// 735px and Up
$h2-font-size-medium: 48px;
$h2-line-height-medium: 1.08349;
$h2-letter-spacing-medium: -0.002em;

// 1068px and Up
$h2-font-size-large: 56px;
$h2-line-height-large: 1.07143;
$h2-letter-spacing-large: -0.005em;

//////////          //////////

////////// Header 6 //////////
$h6-font-size-small: 17px;
$h6-line-height-small: 1.23536;
$h6-font-weight-small: 400;
$h6-letter-spacing-small: -0.022em;
$h6-font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica";
//////////          //////////

$gutters: 16px;
$gutters-desktop: calc($gutters * 2);

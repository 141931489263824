.grid-toolbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiInputBase-root {
    min-height: 50px;
  }
}

.grid-toolbar-container__buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}
.table {
  height: inherit;
  $b: ".table";
  margin-bottom: 0;
  // padding-top: 8px;
  &__tabs {
    overflow: visible !important;
    :first-child {
      overflow: visible !important;
      justify-content: flex-start;
    }
  }

  &__tab {
  }

  &__data-grid-container {
    height: inherit;
    #{$b}__data-grid-pro {
      width: 100%;
      // min-height: 500px;
      min-height: 58vh;
      height: inherit;
      // height: 100%;
      border: 1px solid rgba(224, 224, 224, 1);
      .MuiOutlinedInput-root {
        height: fit-content !important;
      }
      .MuiDataGrid-cell,
      .MuiDataGrid-cell--editing {
        padding: 10px 10px !important;
        display: flex;
        // justify-content: center;
      }
      .MuiAutocomplete-input {
        width: fit-content !important;
      }

      &--staff-info {
      }
    }
  }
}

.MuiDataGrid-pinnedColumnHeaders,
.MuiDataGrid-pinnedColumns {
  // z-index: 0 !important;
  background-color: rgb(255 255 255 / 95%) !important;
}

.MuiDataGrid-row {
  &--new {
    background-color: rgb(237, 247, 237);
    &:hover {
      background-color: darken(
        $color: rgb(237, 247, 237),
        $amount: 1
      ) !important;
    }
  }
  &--delete {
    background-color: rgb(253, 237, 237);
    &:hover {
      background-color: darken(
        $color: rgb(253, 237, 237),
        $amount: 1
      ) !important;
    }
  }
}
.MuiDataGrid-cell {
  &--delete {
    background-color: rgb(253, 237, 237);
    &:hover {
      background-color: darken(
        $color: rgb(253, 237, 237),
        $amount: 1
      ) !important;
    }
  }
  &--update {
    background-color: rgb(237, 247, 237);
    &:hover {
      background-color: darken(
        $color: rgb(237, 247, 237),
        $amount: 1
      ) !important;
    }
  }
}

.MuiDataGrid-row {
  &:hover {
    box-shadow: 4px 3px 6px -3px #c3c6d4;
    background-color: rgba(245, 246, 248, 0.97);
  }
}
.MuiDataGrid-cell .MuiDataGrid-cellContent {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px;
}

.MuiDataGrid-cell:hover {
  cursor: pointer !important;

  .MuiDataGrid-cellContent {
    border: 1px solid #c3c6d4;
  }
}


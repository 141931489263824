@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

.settings-selection {
  // margin-top: 10px;
  // max-width: 965px;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  // @include for-tablet-landscape-up {
  //   grid-template-columns: minmax();
  //   grid-template-rows: auto;
  // }
}
.settings-cards {
  width: 100%;
  gap: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(231px, 231px));
  // grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));

  // @include for-tablet-landscape-up {
  //   grid-template-columns: minmax();
  //   grid-template-rows: auto;
  // }
}

.settings-card__header .MuiCardHeader-action {
  margin: 0 !important;
 

}

.settings-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 214px;
  min-width: 231px;
  max-width: 236px;
  box-shadow: none !important;
  transition: all 0.2s ease;
  border: 1px solid #00000021;
  &__action-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.2s ease;
    // &:hover {
    //   ~ .settings-card__actions {
    //     transition: background-color 0.2s ease;

    //     background-color: #f6f6f6;
    //   }
    // }
  }
  &__actions {
    display: flex;
    // gap: 12px;
  }
  &__title,
  &__label,
  &__label-chip,
  &__studios,
  &__date,
  &__icon,
  &__icon-button {
    transition: all 0.2s ease;
  }
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__title {
    font-size: 18px !important;
    // color: #00000042;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 16px !important;
  }
  &__skeleton {
    width: 100%;
    height: 10px;
    background-color: rgba(211, 211, 211, 0.52);
    margin-bottom: 10px;
    border-radius: 999px;
  }

  &__label {
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    // color: #00000042;

    &-chip {
      margin-left: 6px;
      // color: #00000042 !important;
    }
  }
  &__studios {
    margin-bottom: 10px !important;
  }
  &__studios,
  &__date {
    // color: #00000042;
    // color: rgb(137, 137, 137);
  }
  &__setup-button,
  &__loading-overlay,
  &__loading-overlay--active {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    // background-color: rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.137);

    backdrop-filter: blur(2.75px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white !important;
  }
  &__loading-overlay {
    display: none;
    &--active {
      display: flex;
    }
  }

  &__icon-button {
    opacity: 1;
    transform: scale(1);
    max-width: 24px;
    max-height: 24px;
    display: flex;
  }
  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    // border: 1px solid rgb(0 0 0 / 15%);
    border: 1px solid #00000021;

    &--selected {
    }
  }
  &--selected {
    .settings-card__label {
      color: rgb(137, 137, 137);
      &-chip {
        color: #000000de !important;
      }
    }
    .settings-card {
      &__title,
      &__studios,
      &__date {
        color: #000000de;
      }
    }
    .settings-card__select-button {
      background-color: #327feb;
    }
    .settings-card__icon-button {
      animation: pop-in 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      color: #327feb;
    }

    animation: border-pop-in 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  }

  &:hover {
    .settings-card {
      &__label {
        color: rgb(137, 137, 137);
        &-chip {
          color: #000000de !important;
        }
      }
      &__icon {
        border: 1px solid #00000061;
      }
      &__title,
      &__studios,
      &__date {
        color: #000000de;
      }
    }
    // box-shadow: 0 0 11px rgba(33, 33, 33, 0.2) !important;
    border: 1px solid #bebebe;

    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  }
}


.settings-card__skeleton {
  // margin-top: 78px !important;

  &--card {
    height: 231px !important;
    width: 240px !important;
    transform: none !important;
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes border-pop-in {
  0% {
    border: 1px solid #00000021;
  }
  100% {
    border: 2px solid #327feb;
  }
}

@use "../../Styles/variables.scss" as *;
@use "../../Styles/mixins" as mixins;
@use "../../Styles/colors.scss" as *;

.footer__seperator {
  height: 1px;
  width: 100%;
  background: rgb(242, 242, 242);
  margin-top: 32px;
  margin-bottom: 0;
}

.footer {
  width: 100%;
  padding: $gutters;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 32px;

  @include mixins.for-tablet-portrait-up {
    flex-direction: row;
    justify-content: center;
    text-align: left;
    align-items: flex-start;
  }

  @include mixins.for-tablet-landscape-up {
    padding: $gutters-desktop;
  }
}

.footer-logo {
  order: 1;

  a {
    width: min-content;
  }
  div {
    margin-right: 0px !important;
  }
  img {
    height: 1em; /* Fixed size for the logo across all devices */
    // width: auto;  /* Maintain aspect ratio */
    width: min-content;
  }
}

.footer-socials {
  display: flex;
  gap: 16px;
  order: 2;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  order: 3;

  @include mixins.for-tablet-portrait-up {
    flex-direction: row;
    gap: 20px;
  }
}

.footer__link {
  display: block;
  color: $link-color !important;
  text-decoration: none !important;

  &:hover {
    color: lighten($color: $link-color, $amount: 20) !important;
  }
}

.footer__social-link {
  font-size: 24px;
  color: $link-color;

  &:hover {
    color: lighten($color: $link-color, $amount: 20);
  }
}


@include mixins.for-tablet-portrait-up {
  .footer {
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
  }
}

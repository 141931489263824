

.staff-emailer {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.staff-emailer__heading {
  margin-bottom: 0.5rem;
}

.staff-emailer__subheading {
  margin-bottom: 1.5rem;
  color: #666;
}

/* Two-column layout for desktop, stack on mobile */
.staff-emailer__content {
  display: flex;
  // flex-direction: row;
  flex-direction: column;

  gap: 2rem;
  margin-top: 1.5rem;
  // @media (max-width: 960px) {
  //   flex-direction: column;
  // }
}

.staff-emailer__left {
  flex: 1 1 40%;
}

.staff-emailer__right {
  flex: 1 1 60%;
}

/* Just to ensure the dropzone box doesn't expand too large, remove big margin. */
.staff-emailer-dropzone {
  margin-top: 0;
}

.staff-emailer__zero-earnings-alert {
  margin-bottom: 1em;
}

.staff-emailer__staff-settings-link {
  margin: 10px 16px;
  padding-left: 8px;
}

// .staff-emailer__table-section {
//   margin-top: 1rem;
// }

.staff-emailer__skeleton {
  width: 266px;
  height: 328px;
  margin-top: 20px !important;
  transform: none !important;
}





// table


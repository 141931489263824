/* styles.css */
.custom-placeholder {
  --amplify-components-placeholder-start-color: purple;
  --amplify-components-placeholder-end-color: blue;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.page {
  margin-top: 68px;
  padding: 0 0 0 0;
  width: 100%;
  margin-bottom: 200px;

  @media (min-width: 900px) {
    margin-top: 95px;
  }
}

.section-container {
  width: 100%;
  @media (min-width: 735px) {
  }
}
.section-container-small {
  width: 100%;
  @media (min-width: 735px) {
  }
  @media (min-width: 1068px) {
    max-width: 650px;
  }
}
